import React from "react";

import "./App.css";

import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Guess The Prompt</h1>
      <hr />
      <div className="row mb-3 player-bar">
        <div className="col">
          <p className="">
            Player Name: <span id="playerName">John Doe</span>
          </p>
        </div>
        <div className="col">
          <p>
            Score: <span id="score">0</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="image-container">
            <img
              src="images/0000000030.jpg"
              className="img-fluid"
              alt="Guess what the image is"
            />
          </div>
          <form id="guessForm" className="guess-area">
            <div className="mb-3">
              <label htmlFor="guessInput" className="form-label">
                Enter your guess:
              </label>
              <textarea
                className="form-control"
                id="guessInput"
                rows="3"
                required
              ></textarea>
            </div>
            <div className="button-bar">
              <button
                type="submit"
                className="btn btn-primary"
                id="btn-submit-guess"
              >
                Submit Guess
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default App;
